<!-- 
  NOTE: This component was made for a specific purpose and isn't
  designed/tested for general use. Though the API mimics the standard Vuetify
  v-select API, only a small subset of the features are included.
-->
<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        class="app-chip-select"
        :style="
          {
            'width': width,
            'max-width': maxWidth
          }
        "
        :color="color"
        text-color="white"
      >
        <div class="d-flex align-center justify-space-between" style="width: 100%;">
          <!-- TODO: Handle nested properties. -->
          <span>{{ selectedItem != null ? selectedItem[itemText] : ''}}</span>
          <v-icon>mdi-menu-down</v-icon>
        </div>
      </v-chip>
    </template>
    <v-list dense>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="modelValueChange(item)"
        style="cursor: pointer;"
      >
        <!-- TODO: Handle nested properties. -->
        <v-list-item-title v-text="item[itemText]"/>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "AppChipSelect",

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    width: {
      type: String,
      required: false,
      default: null,
    },
    maxWidth: {
      type: String,
      required: false,
      default: null,
    },
  },

  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },

  data: () => ({
    //
  }),

  computed: {
    selectedItem() {
      return this.items.find(i => i[this.itemValue] == this.modelValue)
    }
  },

  methods: {
    modelValueChange(item) {
      this.$emit('update:modelValue', item[this.itemValue])
      this.$emit('change', item)
    }
  }
}
</script>

<style lang="scss">
.app-chip-select {
  .v-chip__content {
    width: 100%;
  }
}
</style>