<template>
  <view-card-template :loaded="loaded">

    <template #cardTitle>
      Award Management
    </template>
  
    <template #cardBody>

      <!-- START Awards List Table -->
      <v-data-table
        :headers="headers"
        :items="filteredAwards"
        :items-per-page="-1"
        class="expanding-v-data-table"
        dense
      >
        <template #top>
          <!-- START Filters -->
          <v-row class="mx-2">
            <!-- Title -->
            <v-col
              cols="12"
              sm="2"
            >
              <v-text-field
                v-model="filterTitle"
                label="Filter by Title"
                hide-details
                clearable
              />
            </v-col>
            
            <!-- Sport -->
            <v-col
              cols="12"
              sm="2"
            >
              <v-select
                v-model="filterGlobalSportId"
                :items="ballots"
                item-text="sportName"
                item-value="globalSportId"
                label="Filter by Sport"
                hide-details
                clearable
              />
            </v-col>
            <!-- Season -->
            <v-col
              cols="12"
              sm="2"
            >
              <v-select
                v-model="filterSeason"
                :items="ballots"
                item-text="season"
                item-value="season"
                label="Filter by Season"
                hide-details
                clearable
              />
            </v-col>
            <!-- Type -->
            <v-col
              cols="12"
              sm="2"
            >
              <v-select
                v-model="filterType"
                :items="ballots"
                item-text="ballotTypeName"
                item-value="ballotType"
                label="Filter by Type"
                hide-details
                clearable
              />
            </v-col>
            <!-- Phase -->
            <v-col
              cols="12"
              sm="2"
            >
              <v-combobox
                v-model="filterPhase"
                :items="ballotPhases"
                item-text="name"
                item-value="value"
                label="Filter by Phase"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <!-- Display a maximum of maxFilterPhasesToDisplay phases and truncate the rest -->
                  <span v-if="index < maxFilterPhasesToDisplay">{{ item?.name }} &nbsp;</span>
                  <span
                    v-if="index === maxFilterPhasesToDisplay"
                    class="grey--text caption"
                  >(+{{ filterPhase.length - maxFilterPhasesToDisplay }} phases)</span>
                </template>
              </v-combobox>
            </v-col>
            <!-- Batch Reset -->
            <v-col
              cols="12"
              sm="2"
            >
            
              <div class="d-flex align-center" style="height: 80%;">
                <v-btn
                  @click="openBatchResetModal()"
                  color="primary"
                  :disabled="ballotsAreMarkedForReset"
                  
                >
                  <v-icon>mdi-recycle</v-icon>
                </v-btn>
                <v-checkbox
                  :label="!allSelected ? 'Select all' : 'Deselect all'"
                  @click="!allSelected ? selectAll() : deselectAll()"
                  v-model="allSelectedCheck"
                  class="batch-reset-checkbox ma-0 pa-0"
                  hide-details
                  dense
                />
              </div>
            </v-col>
          </v-row>
          <!-- END Filters -->
        </template>

        <template #item="{ item }">
          <tr>
            <td class="font-weight-bold">{{ item.title }}</td>
            <td>{{ item.sportName }}</td>
            <td style="white-space: nowrap;">{{ formatDate(item.gamesOccuringBetweenStartDate) }}</td>
            <td style="white-space: nowrap;">{{ formatDate(item.gamesOccuringBetweenEndDate) }}</td> <!-- TODO: Should these be phase dates? -->
            <td style="max-width: 150px;" class="py-1">
              <v-row no-gutters>
                <!-- Phase Select-->
                <v-col
                  cols="12"
                >
                  <div
                    class="d-flex align-center"
                    style="height: 100%;"
                  >
                    <app-chip-select
                      v-model="item.selectedBallotPhase"
                      @change="changeBallotPhase(item)"
                      :items="ballotPhases"
                      item-text="name"
                      item-value="value"
                      width="100%"
                      max-width="144px"
                      :color="
                        {
                          1:'blue darken-2',
                          2:'amber darken-2',
                          3:'green darken-2',
                          4:'grey',
                          5:'amber darken-4',
                          6:'green darken-4'
                        }[item.selectedBallotPhase]
                      "
                    />
                  </div>
                </v-col>
              </v-row>
            </td>
            <td>
              <v-row no-gutters>
                <v-col cols="12">
                  <div 
                    class="w-100 d-flex align-center justify-center"
                    style="height: 100%;"
                  >
                    <!-- Edit -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="editBallot(item)"
                          :to="`/admin/edit-award/${item.id}`"
                          icon
                        >
                          <v-icon   
                            color="blue darken-2"
                            small
                          >
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                      Edit Award
                    </v-tooltip>
                    <!-- Nomination Results -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :to="`/admin/nomination-results/${item.id}`"
                          icon
                        >
                          <v-icon   
                            color="amber darken-2"
                            small
                          >
                            mdi-trophy-award
                          </v-icon>
                        </v-btn>
                      </template>
                      Nominations
                    </v-tooltip>
                    <!-- Ballot -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :to="`/admin/view-ballot/${item.id}`"
                          icon
                        >
                          <v-icon   
                            color="blue-grey darken-1"
                            small
                          >
                            mdi-ballot
                          </v-icon>
                        </v-btn>
                      </template>
                      Ballot
                    </v-tooltip>
                    <!-- Voting Results -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :to="`/admin/voting-results/${item.id}`"
                          icon
                        >
                          <v-icon   
                            color="green darken-2"
                            small
                          >
                            mdi-vote
                          </v-icon>
                        </v-btn>
                      </template>
                      Votes
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="copyItem(item)"
                          icon
                        >
                          <v-icon   
                            color="blue-grey darken-1"
                            small
                          >
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      Copy
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </td>
            <td>
              <div class="d-flex justify-center">
                <v-checkbox
                  v-model="item.markedForBatchReset"
                  class="batch-reset-checkbox ma-0 pa-0"
                  hide-details
                  dense
                />
              </div>
            </td>
          </tr>
        </template>

        <template #footer="{ props }">
          <v-divider v-if="props.pagination.itemsLength > 0"/>
          <div class="expanding-v-data-table--footer"></div>
        </template>
      </v-data-table>
      <!-- END Awards List Table -->

      <!-- Batch Reset Modal -->
      <app-action-modal
        @cancel="batchResetModal = false"
        @action="batchReset()"
        :value="batchResetModal"
        :loading="batchResetLoading"
        header-text="Batch Reset Confirmation"
        action-word="reset"
        warning-text="Are you sure you want to reset the selected award(s)? This will delete all nominations and votes, which cannot be restored once reset."
      />

     
      <app-modal
        :value="copyAwardModal"
        :persistent="true"
        header-text="Copy Award"
        width="auto"
        min-width="500px"
      >
        <template #content>
          <div 
            class="px-2 mt-2"
            style="max-height: 450px;"
          >
            Do you want to copy nomination info
            <v-btn
              @click="duplicateAward(true)"
              class="ml-2"
              color="primary"
              depressed
            >
              Yes
            </v-btn>
            <v-btn
              @click="duplicateAward(false)"
              class="ml-2"
              color="primary"
              depressed
            >
              No
            </v-btn>
          </div>
           
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="copyAwardModal = false"
            depressed
          >
            Cancel
          </v-btn>
        </template>
      </app-modal>
    </template>
  </view-card-template>
</template>

<script>
import AppChipSelect from "@/components/app/AppChipSelect"
import AppModal from "@/components/app/AppModal"
import AppActionModal from "@/components/app/AppActionModal"
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"

import moment from 'moment'

export default {
  name: "AwardManagement",

  components: {
    AppChipSelect,
    AppActionModal,
    ViewCardTemplate,
    AppModal
  },

  data: () => ({
    loaded: false,
    allSelectedCheck: false,
    headers: [
      { text: 'Title', value: "title", width: "30%", },
      { text: 'Sport', value: "sportName", width: "10%", },
      { text: 'Start Date', value: "gamesOccuringBetweenStartDate", width: "10%", },
      { text: 'End Date', value: "gamesOccuringBetweenEndDate", width: "10%", },
      { text: "Ballot Phase", sortable: false, width: "25%", },
      { text: "Actions", sortable: false, align: "center", width: "10%", },
      { text: "Reset", sortable: false, align: "center", width: "5%", },
      // Note: widths add up to 90%, if you go all the way up to 100% the headers get funky.
    ],
    
    ballots: [],
    ballotPhases: [],

    filterTitle: null,
    filterGlobalSportId: null,
    filterSeason: null,
    filterType: null,
    filterPhase: [],
    maxFilterPhasesToDisplay: 1,

    ballotIdsToReset: [],
    batchResetModal: false,
    copyAward: {},
    copyAwardModal: false,
    batchResetLoading: false,
  }),

  computed: {
    filteredAwards() {
      return this.ballots
        .filter(ballot => this.filterTitle == null || this.filterTitle.length == 0
          || ballot.title.toLocaleLowerCase().includes(this.filterTitle.toLocaleLowerCase())
        )
        .filter(ballot => this.filterGlobalSportId == null || this.filterGlobalSportId == ballot.globalSportId)
        .filter(ballot => this.filterSeason == null || this.filterSeason.length == 0 || this.filterSeason == ballot.season)
        .filter(ballot => this.filterType == null || this.filterType == ballot.ballotType)
        .filter(ballot => this.filterPhase == null || this.filterPhase.length == 0 
          || this.filterPhase.some(phase => phase.value === ballot.selectedBallotPhase)
        )
    },

    ballotsAreMarkedForReset() {
      return this.ballots.find(ballot => ballot.markedForBatchReset) == null
    },
    allSelected() {
      return this.filteredAwards
        .filter(ballot => ballot.markedForBatchReset).length == this.filteredAwards.length
    },
  },

  methods: {
    async copyItem(item) {
      const ballotRes = await Controllers.BallotController.AdminGetBallot(item.id)
      if(ballotRes && !ballotRes.hasError) {
        this.copyAward =  ballotRes.data
        this.copyAwardModal = true
      }
      else {
        this.copyAwardModal = false
      }
      
    },
    async duplicateAward(hasNomination) {
      let awards = this.copyAward.awards
      if (!hasNomination) {
        awards = this.copyAward.awards.map(a => {
          a.nominations= []
          
          return a
        })
        this.copyAward.awards = awards
      }
      delete this.copyAward.id
      

      let success = false
    
        // Creating a new Award.
      const res = await Controllers.BallotController.AdminCreateBallot(this.copyAward)
      success = res && !res.hasError && res.data
      if(success) this.$root.showSnackbar('Award created successfully!', 'success', 5000)
      else this.$root.showSnackbar('Failed to create award.', 'error', 5000)
      const savedCopy = res.data
      awards.map((a, i) => {
        a.nominations.map(async (n) => {
          n.awardId = savedCopy.awards[i].id
          n.ballotId = savedCopy.awards[i].ballotId
          n.createdOn = new Date()
          n.modifiedOn = new Date()
          delete n.votes
          delete n.nominator
          delete n.id
          const res = await Controllers.NominationController.CreateCopyNomination(n)
          if(res && !res.hasError) {
            this.$root.showSnackbar('Nomination submitted successfully!', "success", 5000)
          }
          else {
            this.$root.showSnackbar('There was an error submitting your nomination.', "error", 5000)
          }
        })
      })
      await this.getBallotList()
      this.copyAwardModal = false
    },
    openBatchResetModal() {
      this.ballotIdsToReset = this.ballots
        .filter(ballot => ballot.markedForBatchReset)
        .map(ballot => ballot.id)
      this.batchResetModal = true
    },
    
    async batchReset() {
      this.batchResetLoading = true

      const res = await Controllers.BallotController.AdminBatchReset(this.ballotIdsToReset)

      if(res && !res.hasError) {
        res.data.forEach(ballotId => {
          const ballot = this.ballots.find(b => b.id == ballotId)
          
          let gamesOccuringBetweenStartDate = new Date(ballot.gamesOccuringBetweenStartDate)
          ballot.gamesOccuringBetweenStartDate = gamesOccuringBetweenStartDate.setDate(gamesOccuringBetweenStartDate.getDate() + 7);
          let gamesOccuringBetweenEndDate = new Date(ballot.gamesOccuringBetweenEndDate)
          ballot.gamesOccuringBetweenEndDate = gamesOccuringBetweenEndDate.setDate(gamesOccuringBetweenEndDate.getDate() + 7);        

          ballot.markedForBatchReset = false
          ballot.phaseManuallyOverriden = true
          ballot.overridePhase = 1
          ballot.selectedBallotPhase = 1
          ballot.selectedPhaseName = this.ballotPhases.find(ballotPhase => ballotPhase.value == ballot.selectedBallotPhase)?.name
        })
        this.$root.showSnackbar('Award(s) reset successfully!', "success", 5000)
      }
      else {
        this.$root.showSnackbar('Failed to reset award(s).', "error", 5000)
      }
      
      this.batchResetLoading = false
      this.ballotIdsToReset = []
      this.batchResetModal = false
    },

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },

    async changeBallotPhase(ballot) {
      // TODO: Error handling
      await Controllers.BallotController.AdminPatchBallotPhase({ overridePhase: ballot.selectedBallotPhase }, ballot.id)
    },

    editBallot(ballot) {
      this.$router.push({ name: "Edit Award", params: { ballotId: ballot.id } })
    },

    seeBallotNominationResults(ballot) {
      this.$router.push({ name: "NominationResults", params: { ballotId: ballot.id } })
    },

    seeBallotVotingResults(ballot) {
      this.$router.push({ name: "VotingResults", params: { ballotId: ballot.id } })
    },

    async getSportNameByGlobalSportId(globalSportId) {
      const sports = await this.$store.getters.conferenceSports
      const sport = sports.find(s => s.globalSportId == globalSportId)
      return sport == null ? 'N/A' : sport?.name
    },

    async mapBallot(ballot) {
      Controllers.BallotController.interpretDatesAsUtc(ballot)

      // Sport Name
      ballot.sportName = await this.getSportNameByGlobalSportId(ballot.globalSportId)

      // Ballot type name
      const ballotTypes = await this.$store.getters.ballotTypes
      ballot.ballotTypeName = ballotTypes.find(type => type.value == ballot.ballotType)?.name

      // Select items
      this.ballotPhases = await this.$store.getters.ballotPhases
      
      // Default value for phase filter value (i.e. all but archived because
      // we want archived awards to be hidden by default)
      this.filterPhase = this.ballotPhases
        .filter(phase => phase?.name !== 'Archived')

      // State for each chip select
      // Note: The override has precedence over date ranges.
      ballot.selectedBallotPhase = Controllers.BallotController.GetCurrentBallotPhase(ballot)
      ballot.selectedPhaseName = this.ballotPhases.find(ballotPhase => ballotPhase.value == ballot.selectedBallotPhase)?.name
    },

    async getBallotList() {
      const res = await Controllers.BallotController.AdminListBallots()

      if(res && !res.hasError) {
        await Promise.all(res.data.map((ballot) => this.mapBallot(ballot)))
        this.ballots = res.data
      }
      else {
        this.$root.showSnackbar('Failed to find Awards.', "error", 5000)
      }
    },
    
    selectAll(){
      this.filteredAwards.map(async(award  ) => {
        this.$set(award, 'markedForBatchReset', true)
      })
    },
    deselectAll(){
      this.filteredAwards.map(async(award  ) => {
        this.$set(award, 'markedForBatchReset', false)
      })
    }
  },
  watch: {
    filteredAwards: {
      handler() {
        this.allSelectedCheck = this.filteredAwards
        .filter(ballot => ballot.markedForBatchReset).length == this.filteredAwards.length
      },
      deep: true
    }
  },
  async created() {
    this.$root.updateTitle("Award Management")

    await this.getBallotList()

    this.loaded = true
  },
  
}
</script>

<style lang="scss">
.batch-reset-checkbox .v-input--selection-controls__input {
  margin-right: 0px;
}
</style>